<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Add Project"
    @close="onCloseModal">
    <div>
      <call-input
        v-model="$v.dataForm.name.$model"
        type-input="text"
        :valide="$v.dataForm.name.$error"
        float-name-label="Project Name" />
      <div
        :class="[
          'call-select-modal__wrapper',
          'call-input',
          !dataForm.team_id ? 'call-select--empty' : null,
          $v.dataForm.team_id.$error ? 'error-valide' : null
        ]">
        <v-select
          v-model="$v.dataForm.team_id.$model"
          :options="dataSelectOptionsTeam"
          label="name"
          :components="{ Deselect: dataComponentDeselect }"
          placeholder="Team"
          class="call-select-modal call-select">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя оффиса
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <label>Team</label>
      </div>
      <b-form-checkbox
        v-model="dataForm.vip"
        class="call-checkbox"
        size="lg"
        :value="true">
        VIP status
      </b-form-checkbox>

      <b-form-checkbox
        id="switch-id"
        v-model="dataForm.bonuses_available"
        class="call-checkbox"
        name="check-button"
        switch
        :value="true">
        Bonuses Available
      </b-form-checkbox>

      <div
        v-if="dataForm.bonuses_available && allBonus.length"
        class="wrap-bonuses">
        <b-form-checkbox-group
          v-model="selectBonuses"
          :options="allBonus"
          class="mb-3"
          value-field="id"
          text-field="description" />
      </div>

      <div class="upload__title">
        Players List
      </div>
      <call-uploader @on-files="onFiles" />
    </div>
    <template #modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataAddIcon"
        :disabled="$v.$invalid"
        type="success"
        @click="clickAdd()">
        Add project
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BFormCheckbox, BFormCheckboxGroup } from 'bootstrap-vue';
import CallButton from '../CallButton';
import CallInput from '../CallInput';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import {
  PROJECTS_CREATE,
  TEAMS_GET_ALL,
  BONUS_LIST,
  SET_BONUS_LIST_PROJECT
} from '../../../store/types/actions.types';
import iconsModal from '../../../mixins/iconsModal';
import selectAllTeams from '../../../mixins/select/selectAllTeams';
import CallUploader from '../../desktop/CallUploader';
import { mapGetters } from 'vuex';

export default {
  name: 'CallModalAddProject',
  components: {
    CallUploader,
    CallInput,
    CallButton,
    BModal,
    BFormCheckbox,
    BFormCheckboxGroup
  },
  mixins: [validationMixin, iconsModal, selectAllTeams],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataForm: {
        name: null,
        team_id: null,
        files: null,
        vip: false,
        bonuses_available: false
      },
      selectBonuses: [],
      allBonus: []
    };
  },
  validations: {
    dataForm: {
      name: {
        required
      },
      team_id: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      bonusesList: 'bonus/bonusesList',
      bonusesListProject: 'bonus/bonusesListProject'
    })
  },
  watch: {
    async value () {
      this.dataModal = this.value;
      if (this.dataModal) {
        this.selectBonuses = [];
        this.getAllBonus();
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();
        const {
          payload: { list = [] }
        } = await this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
          params: {
            short: true
          }
        });
        this.dataSelectOptionsTeam = list.filter(item => item.is_active);
      }
    }
  },
  methods: {
    async getAllBonus () {
      await this.$store.dispatch(`bonus/${BONUS_LIST}`);
      this.allBonus = this.bonusesList;
    },

    onCloseModal () {
      this.$emit('input', false);
    },
    onFiles (files) {
      if (files.length === 0) {
        this.dataForm.files = null;
        return;
      }
      this.dataForm.files = files[0];
    },
    async clickAdd () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      const formData = new FormData();
      if (this.dataForm.files) {
        formData.append('file', this.dataForm.files);
      }
      formData.append('name', this.dataForm.name);
      formData.append('team_id', this.dataForm.team_id?.id);
      formData.append('vip', this.dataForm.vip);
      formData.append('bonuses_available', this.dataForm.bonuses_available);

      try {
        const projectData = await this.$store.dispatch(`project/${PROJECTS_CREATE}`, {
          params: formData,
          context: this
        });
        await this.$store.dispatch(
          `bonus/${SET_BONUS_LIST_PROJECT}`,
          {
            projectId: projectData.id,
            params: {
              bonus_ids: this.dataForm.bonuses_available ? this.selectBonuses : []
            }
          }
        );
        this.onCloseModal();
        this.$emit('on-update');
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
