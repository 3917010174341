/* eslint-disable jsx/factory-in-scope */
export default {
  name: 'CallCheckedClosedOpened',
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      dataModelChecked: this.value
    };
  },
  watch: {
    dataModelChecked () {
      this.$emit('input', this.dataModelChecked);
    },
    value (v) {
      this.dataModelChecked = v;
    }
  },
  created () {
    this.clickAction = (() => {
      let _type = this.value;
      return (type) => {
        if (_type === type) {
          this.dataModelChecked = null;
        }
        _type = type;
      };
    })();
  },
  render (h) {
    return (
      <div class="switcher-wrapper">
        <div class="switcher-item">
          <input
            id="radio-1"
            vModel={this.dataModelChecked}
            value="opened"
            type="radio"
            onClick={() => this.clickAction('opened')}
            name="radio">  </input>
          <label for="radio-1">Opened</label>
        </div>
        <div class="switcher-item">
          <input
            id="radio-2"
            vModel={this.dataModelChecked}
            value="closed"
            type="radio"
            onClick={() => this.clickAction('closed')}
            name="radio"> </input>
          <label for="radio-2">Closed</label>
        </div>
      </div>
    );
  }
};
