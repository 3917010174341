var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","no-close-on-backdrop":"","no-close-on-esc":"","title":"Add Project"},on:{"close":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('call-button',{attrs:{"icon":_vm.dataCloseIcon,"type":"danger","plain":""},on:{"click":function($event){return _vm.onCloseModal()}}},[_vm._v(" Cancel ")]),_c('call-button',{attrs:{"icon":_vm.dataAddIcon,"disabled":_vm.$v.$invalid,"type":"success"},on:{"click":function($event){return _vm.clickAdd()}}},[_vm._v(" Add project ")])]},proxy:true}]),model:{value:(_vm.dataModal),callback:function ($$v) {_vm.dataModal=$$v},expression:"dataModal"}},[_c('div',[_c('call-input',{attrs:{"type-input":"text","valide":_vm.$v.dataForm.name.$error,"float-name-label":"Project Name"},model:{value:(_vm.$v.dataForm.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.dataForm.name, "$model", $$v)},expression:"$v.dataForm.name.$model"}}),_c('div',{class:[
        'call-select-modal__wrapper',
        'call-input',
        !_vm.dataForm.team_id ? 'call-select--empty' : null,
        _vm.$v.dataForm.team_id.$error ? 'error-valide' : null
      ]},[_c('v-select',{staticClass:"call-select-modal call-select",attrs:{"options":_vm.dataSelectOptionsTeam,"label":"name","components":{ Deselect: _vm.dataComponentDeselect },"placeholder":"Team"},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
      var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('font-awesome-icon',{attrs:{"icon":_vm.dataIconDown}})],1)]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_vm._v(" "+_vm._s(option.name)+" ")])]}}]),model:{value:(_vm.$v.dataForm.team_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.dataForm.team_id, "$model", $$v)},expression:"$v.dataForm.team_id.$model"}},[_c('template',{slot:"no-options"},[_vm._v(" Введите имя оффиса ")])],2),_c('label',[_vm._v("Team")])],1),_c('b-form-checkbox',{staticClass:"call-checkbox",attrs:{"size":"lg","value":true},model:{value:(_vm.dataForm.vip),callback:function ($$v) {_vm.$set(_vm.dataForm, "vip", $$v)},expression:"dataForm.vip"}},[_vm._v(" VIP status ")]),_c('b-form-checkbox',{staticClass:"call-checkbox",attrs:{"id":"switch-id","name":"check-button","switch":"","value":true},model:{value:(_vm.dataForm.bonuses_available),callback:function ($$v) {_vm.$set(_vm.dataForm, "bonuses_available", $$v)},expression:"dataForm.bonuses_available"}},[_vm._v(" Bonuses Available ")]),(_vm.dataForm.bonuses_available && _vm.allBonus.length)?_c('div',{staticClass:"wrap-bonuses"},[_c('b-form-checkbox-group',{staticClass:"mb-3",attrs:{"options":_vm.allBonus,"value-field":"id","text-field":"description"},model:{value:(_vm.selectBonuses),callback:function ($$v) {_vm.selectBonuses=$$v},expression:"selectBonuses"}})],1):_vm._e(),_c('div',{staticClass:"upload__title"},[_vm._v(" Players List ")]),_c('call-uploader',{on:{"on-files":_vm.onFiles}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }