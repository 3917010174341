<template>
  <div class="table_actions">
    <call-tooltip
      placement="top"
      content="Info">
      <call-button
        outlet
        type="success"
        round
        not-border
        :icon="dataViewIcon"
        @click="clickAction('view')" />
    </call-tooltip>
    <call-tooltip
      v-if="isAdmin"
      placement="top"
      content="Edit">
      <call-button
        outlet
        type="warning"
        round
        not-border
        :icon="dataReadingIcon"
        @click="clickAction('update')" />
    </call-tooltip>
    <call-tooltip
      v-if="blocked"
      placement="top"
      content="Deactivate">
      <call-button
        outlet
        type="danger"
        round
        not-border
        :icon="dataLockIcon"
        @click="clickAction('blocked')" />
    </call-tooltip>
  </div>
</template>

<script>
import CallButton from '../common/CallButton';
import { faEye, faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallTableProjectActions',
  components: { CallButton },
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    blocked: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dataViewIcon: faEye,
      dataReadingIcon: faPen,
      dataLockIcon: faPowerOff
    };
  },
  methods: {
    clickAction (action) {
      this.$emit('on-action', action);
    }
  }
};
</script>
