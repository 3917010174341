import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';

export default {
  data () {
    return {
      dataComponentDeselect: {
        render: createElement => createElement(FontAwesomeIcon, {
          props: {
            icon: faTimes
          }
        })
      },
      dataIconDown: faChevronDown,
      dataSelectLastDayValueDefault: {
        key: -1,
        label: 'All'
      },
      dataSelectLastDayValue: {
        key: -1,
        label: 'All'
      },
      dataSelectOptionsLastDay: [
        {
          key: -1,
          label: 'All'
        },
        // {
        //   key: 'month',
        //   label: 'This Month'
        // },
        // {
        //   key: 'last_month',
        //   label: 'Last Month'
        // }
        {
          key: 'day',
          label: 'Today'
        },
        {
          key: 'last_day',
          label: 'Yesterday'
        },
        {
          key: 'week',
          label: 'This Week'
        },
        {
          key: 'last_week',
          label: 'Last Week'
        },
        {
          key: 'month',
          label: 'This Month'
        },
        {
          key: 'last_month',
          label: 'Last Month'
        }
      ]
    };
  }
};
