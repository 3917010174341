export default {
  data () {
    return {
      dataMixinUserFields: [
        {
          label: '',
          key: 'isBlocked',
          tdClass: 'call-tabel-icon',
          thClass: 'call-tabel-icon call-tabel--span'
        },
        {
          label: 'ID',
          key: 'id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th call-tabel--span'
        },
        {
          label: 'Name',
          key: 'name',
          sortable: false,
          tdClass: 'call-td call-td--bold',
          thClass: 'call-th call-tabel--span'
        },
        {
          label: 'Team',
          key: 'team_name',
          sortable: false,
          tdClass: 'call-td call-td--bold',
          thClass: 'call-th call-tabel--span'
        },
        {
          label: 'All Tasks',
          key: 'all_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'Open Free',
          key: 'open_free_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'Open Assigneed',
          key: 'open_assigned_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: ' Auto Closed Free',
          key: 'auto_closed_free_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'Auto Closed Assigned',
          key: 'auto_closed_assigned_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'Manually Closed',
          key: 'manually_closed_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'Timeout Closed',
          key: 'timeout_closed_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action',
          thClass: 'call-tabel-action call-tabel--span'
        }
      ]
    };
  },
  methods: {
    onHover (item) {
      item.actions = true;
    },
    onUnHover (item) {
      item.actions = false;
    }
  }
};
