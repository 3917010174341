<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Edit Project"
    @close="onCloseModal">
    <div>
      <b-form-checkbox
        v-model="dataForm.vip"
        class="call-checkbox"
        size="lg">
        VIP status
      </b-form-checkbox>

      <b-form-checkbox
        id="switch-id"
        v-model="dataForm.bonuses_available"
        class="call-checkbox"
        name="check-button"
        switch
        :value="true">
        Bonuses Available
      </b-form-checkbox>

      <div
        v-if="dataForm.bonuses_available && allBonus.length"
        class="wrap-bonuses">
        <b-form-checkbox-group
          v-model="selectBonuses"
          :options="allBonus"
          class="mb-3"
          value-field="id"
          text-field="description" />
      </div>
    </div>
    <template #modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataAddIcon"
        type="success"
        @click="clickEdit()">
        Edit project
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BFormCheckbox, BFormCheckboxGroup } from 'bootstrap-vue';
import CallButton from '../CallButton';
import {
  PROJECTS_UPDATE,
  BONUS_LIST,
  GET_BONUS_LIST_PROJECT,
  SET_BONUS_LIST_PROJECT
} from '../../../store/types/actions.types';
import iconsModal from '../../../mixins/iconsModal';
import selectAllTeams from '../../../mixins/select/selectAllTeams';
import { mapGetters } from 'vuex';

export default {
  name: 'CallModalAddProject',
  components: {
    CallButton,
    BModal,
    BFormCheckbox,
    BFormCheckboxGroup
  },
  mixins: [iconsModal, selectAllTeams],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    projectData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataForm: {
        vip: this.projectData.vip,
        bonuses_available: this.projectData.bonuses_available
      },
      selectBonuses: [],
      allBonus: []
    };
  },

  computed: {
    ...mapGetters({
      bonusesList: 'bonus/bonusesList',
      bonusesListProject: 'bonus/bonusesListProject'
    })
  },

  watch: {
    async value () {
      this.dataModal = this.value;
      if (this.value) {
        this.selectBonuses = [];
        this.getAllBonus();
        this.dataForm = {
          vip: this.projectData.vip,
          bonuses_available: this.projectData.bonuses_available
        };
      }
    }
  },
  methods: {
    async getAllBonus () {
      await this.$store.dispatch(`bonus/${BONUS_LIST}`);
      await this.$store.dispatch(`bonus/${GET_BONUS_LIST_PROJECT}`, {
        projectId: this.projectData.id
      });
      this.allBonus = this.bonusesList;
      this.selectBonuses = this.bonusesListProject.map(el => el.id);
    },

    onCloseModal () {
      this.$emit('input', false);
    },

    async clickEdit () {
      const formData = new FormData();

      formData.append('vip', this.dataForm.vip);
      formData.append('bonuses_available', this.dataForm.bonuses_available);

      try {
        await this.$store.dispatch(`project/${PROJECTS_UPDATE}`, {
          params: {
            id: this.projectData.id,
            formData: {
              vip: this.dataForm.vip,
              bonuses_available: this.dataForm.bonuses_available
            }
          }
        });
        await this.$store.dispatch(
          `bonus/${SET_BONUS_LIST_PROJECT}`,
          {
            projectId: this.projectData.id,
            params: {
              bonus_ids: this.dataForm.bonuses_available ? this.selectBonuses : []
            }
          }
        );
        this.onCloseModal();
        this.$emit('on-update');
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
