<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Deactivate Project"
    @close="onCloseModal">
    <div>
      Are you sure you want to deactivate the project ?
    </div>
    <template v-slot:modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataIconDeactive"
        type="success"
        @click="clickDeactivate()">
        Deactivate
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CallButton from '../CallButton';
import { PROJECTS_BLOCKED } from '../../../store/types/actions.types';
import iconsModal from '../../../mixins/iconsModal';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallDeactiveProject',
  components: {
    CallButton,
    BModal
  },
  mixins: [iconsModal],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataIconDeactive: faPowerOff
    };
  },
  watch: {
    async value () {
      this.dataModal = this.value;
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickDeactivate () {
      try {
        await this.$store.dispatch(`project/${PROJECTS_BLOCKED}`, {
          params: {
            projectId: this.projectId
          }
        });
        this.$Notify({
          title: 'Project',
          message: 'Successfully deactivated',
          type: 'success'
        });
        this.onCloseModal();
        this.$emit('on-update');
      } catch (e) {
        throw Error('Ошибка блокировки');
      }
    }
  }
};
</script>
