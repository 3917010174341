<template>
  <div>
    <div
      class="el-upload-dragger upload__dragger"
      :class="{
        'is-dragover': dragover
      }"
      @click="handleClick"
      @keydown="handleKeydown"
      @drop.prevent="onDrop"
      @dragover.prevent="onDragover"
      @dragleave.prevent="dragover = false">
      <div class="el-upload__text">
        Drag and Drop or <em>Select File</em>
      </div>
      <input
        ref="input"
        class="el-upload__input"
        type="file"
        name="file"
        @change="handleChange">
    </div>
    <div
      v-if="computedEmptyFiles"
      class="upload__item">
      <div>
        <font-awesome-icon :icon="dataIconFile" />
        {{ dataFile.name || '' }}
        <span class="success"><font-awesome-icon :icon="dataIconCheck" /></span>
      </div>
      <div>
        <call-button
          outlet
          type="danger"
          :icon="dataIconTimes"
          @click="clearFile()" />
      </div>
    </div>
  </div>
</template>
<script>
import { faCheck, faFile, faTimes } from '@fortawesome/free-solid-svg-icons';
import helper from '../../service/helper';
import CallButton from '../common/CallButton';

export default {
  name: 'CallUploader',
  components: { CallButton },
  props: {
    disabled: Boolean
  },
  data () {
    return {
      dragover: false,
      dataIconFile: faFile,
      dataIconCheck: faCheck,
      dataIconTimes: faTimes,
      dataFile: {}
    };
  },
  computed: {
    computedEmptyFiles () {
      return !helper.isEmpty(this.dataFile);
    }
  },
  methods: {
    handleChange (ev) {
      const files = ev.target.files;
      if (!files) return;
      this.uploadFiles(files);
    },
    clearFile () {
      this.dataFile = null;
      this.$refs.input.value = null;
      this.$emit('on-files', []);
    },
    uploadFiles (files) {
      this.dataFile = files[0];
      this.$emit('on-files', files);
    },
    handleClick () {
      if (!this.disabled) {
        this.$refs.input.value = null;
        this.$refs.input.click();
      }
    },
    handleKeydown (e) {
      if (e.target !== e.currentTarget) return;
      if (e.keyCode === 13 || e.keyCode === 32) {
        this.handleClick();
      }
    },
    onDragover () {
      if (!this.disabled) {
        this.dragover = true;
      }
    },
    onDrop (e) {
      this.uploadFiles(e.dataTransfer.files);
    }
  }
};
</script>
<style scoped>
  .el-upload-dragger {
    background-color: #fff;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .el-upload-dragger .el-upload__text {
    margin: 40px 0 16px;
    color: #222222;
    font-size: 14px;
    text-align: center;
  }

  .el-upload-dragger .el-upload__text em {
    color: #20a052;
    font-style: normal;
  }

  .el-upload__input {
    display: none;
  }
</style>
